<template>
  <v-col cols="11">
    <v-card>
      <v-card-title>
        Timesheet
        <v-spacer />
        <v-select
          name="dateRange"
          v-model="dateRangeSelect.defaultVal"
          class="mr-3"
          :items="dateRangeSelect.items"
          label="Filter Date Range"
          hide-details
          @change="populateTableFromApi"
        ></v-select>

        <v-text-field
          name="projectFilter"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search Project"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :search="search"
        :items="tableItems"
        :loading="loading"
        class="elevation-1"
      ></v-data-table>
    </v-card>
  </v-col>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      search: "",
      tableItems: [],
      loading: true,
      headers: [
        { text: "Login", value: "LogIn", filterable: false },
        { text: "Log Out", value: "LogOut", filterable: false },
        { text: "Project Name", value: "ProjectNM" },
        { text: "Lunch Hour", value: "LunchHour", filterable: false },
        { text: "Hours Worked", value: "HourWorked", filterable: false },
        { text: "Extension", value: "TelephoneExtension", filterable: false }
      ],
      dateRangeSelect: {
        items: [
          { text: "Current Week", value: 0 },
          { text: "1 Week Ago", value: 1 },
          { text: "2 Week Ago", value: 2 },
          { text: "3 Week Ago", value: 3 },
          { text: "Archive", value: null }
        ],
        defaultVal: 0
      }
    };
  },
  created() {
    this.populateTableFromApi();
  },
  computed: {
    ...mapState({
      activeProject: (state) => state.ccms.activeProject
    })
  },
  watch: {
    activeProject() {
      this.populateTableFromApi();
    }
  },
  methods: {
    populateTableFromApi() {
      this.loading = true;
      this.$axios
        .get("/telemarketer/timesheet/list", {
          params: {
            weekago: this.dateRangeSelect.defaultVal
          }
        })
        .then((res) => {
          this.loading = false;
          this.tableItems = res.data;
        });
    }
  }
};
</script>
